@font-face {
font-family: '__kintoSans_3b215b';
src: url(/_next/static/media/c69578de401dfb6a.p.woff2) format('woff2');
font-display: optional;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__kintoSans_3b215b';
src: url(/_next/static/media/198e6f83e58f6077.p.woff2) format('woff2');
font-display: optional;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__kintoSans_3b215b';
src: url(/_next/static/media/e2bc1bb3cc53f561.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__kintoSans_3b215b';
src: url(/_next/static/media/bcc31903dd428af8.p.woff2) format('woff2');
font-display: optional;
font-weight: 300;
font-style: normal;
}@font-face {font-family: '__kintoSans_Fallback_3b215b';src: local("Arial");ascent-override: 83.64%;descent-override: 11.40%;line-gap-override: 0.00%;size-adjust: 105.22%
}.__className_3b215b {font-family: '__kintoSans_3b215b', '__kintoSans_Fallback_3b215b'
}

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg); }
  10% {
    transform: rotate(10deg); }
  40% {
    transform: rotate(-10deg); }
  60% {
    transform: rotate(5deg); }
  80% {
    transform: rotate(-5deg); } }

.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009; }
  .pro-sidebar > .pro-sidebar-inner {
    background: #1d1d1d;
    height: 100%;
    position: relative;
    z-index: 101; }
    .pro-sidebar > .pro-sidebar-inner > img.sidebar-bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      opacity: 0.3;
      left: 0;
      top: 0;
      z-index: 100; }
    .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 101; }
      .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
        border-bottom: 1px solid rgba(173, 173, 173, 0.2); }
      .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
        flex-grow: 1; }
      .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
        border-top: 1px solid rgba(173, 173, 173, 0.2); }
      .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
        list-style-type: none;
        padding: 0;
        margin: 0; }
  .pro-sidebar .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: none; }
  .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px; }
  .pro-sidebar.rtl {
    text-align: right;
    direction: rtl; }
  @media (max-width: 480px) {
    .pro-sidebar.xs {
      position: fixed;
      left: -270px; }
      .pro-sidebar.xs.collapsed {
        left: -80px; }
      .pro-sidebar.xs.toggled {
        left: 0; }
        .pro-sidebar.xs.toggled .overlay {
          display: block; }
      .pro-sidebar.xs.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.xs.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.xs.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 576px) {
    .pro-sidebar.sm {
      position: fixed;
      left: -270px; }
      .pro-sidebar.sm.collapsed {
        left: -80px; }
      .pro-sidebar.sm.toggled {
        left: 0; }
        .pro-sidebar.sm.toggled .overlay {
          display: block; }
      .pro-sidebar.sm.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.sm.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.sm.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 768px) {
    .pro-sidebar.md {
      position: fixed;
      left: -270px; }
      .pro-sidebar.md.collapsed {
        left: -80px; }
      .pro-sidebar.md.toggled {
        left: 0; }
        .pro-sidebar.md.toggled .overlay {
          display: block; }
      .pro-sidebar.md.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.md.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.md.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 992px) {
    .pro-sidebar.lg {
      position: fixed;
      left: -270px; }
      .pro-sidebar.lg.collapsed {
        left: -80px; }
      .pro-sidebar.lg.toggled {
        left: 0; }
        .pro-sidebar.lg.toggled .overlay {
          display: block; }
      .pro-sidebar.lg.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.lg.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.lg.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 1200px) {
    .pro-sidebar.xl {
      position: fixed;
      left: -270px; }
      .pro-sidebar.xl.collapsed {
        left: -80px; }
      .pro-sidebar.xl.toggled {
        left: 0; }
        .pro-sidebar.xl.toggled .overlay {
          display: block; }
      .pro-sidebar.xl.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.xl.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.xl.rtl.toggled {
          left: auto;
          right: 0; } }
  @media (max-width: 1600px) {
    .pro-sidebar.xxl {
      position: fixed;
      left: -270px; }
      .pro-sidebar.xxl.collapsed {
        left: -80px; }
      .pro-sidebar.xxl.toggled {
        left: 0; }
        .pro-sidebar.xxl.toggled .overlay {
          display: block; }
      .pro-sidebar.xxl.rtl {
        left: auto;
        right: -270px; }
        .pro-sidebar.xxl.rtl.collapsed {
          left: auto;
          right: -80px; }
        .pro-sidebar.xxl.rtl.toggled {
          left: auto;
          right: 0; } }

.pro-sidebar .pro-menu.submenu-bullets .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: '';
  display: inline-block;
  width: 4px;
  min-width: 4px;
  height: 4px;
  border: 1px solid #2b2b2b;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  box-shadow: 1px 0px 0px #adadad, 0px -1px 0px #adadad, 0px 1px 0px #adadad, -1px 0px 0px #adadad; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.pro-sidebar .pro-menu {
  padding-top: 10px;
  padding-bottom: 10px; }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    position: relative;
    background-color: #2b2b2b; }
    .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
      padding-top: 15px;
      padding-bottom: 15px; }
  .pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #adadad; }
    .pro-sidebar .pro-menu a:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent; }
    .pro-sidebar .pro-menu a:hover {
      color: #d8d8d8; }
  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px; }
    .pro-sidebar .pro-menu .pro-menu-item.active {
      color: #d8d8d8; }
    .pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
      opacity: 1;
      transition: opacity 0.2s; }
    .pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
      display: flex;
      margin-right: 5px;
      opacity: 1;
      transition: opacity 0.2s; }
    .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 35px 8px 20px;
      cursor: pointer; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
        outline: none;
        color: #d8d8d8; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
        margin-right: 10px;
        font-size: 14px;
        width: 35px;
        min-width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        display: inline-block; }
        .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
        flex-grow: 1;
        flex-shrink: 1; }
      .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
        color: #d8d8d8; }
        .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
          animation: swing ease-in-out 0.5s 1 alternate; }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item:before {
      background: #adadad; }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%); }
      .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
        display: inline-block;
        border-style: solid;
        border-color: #adadad;
        border-width: 0 2px 2px 0;
        padding: 2.5px;
        vertical-align: middle;
        transition: transform 0.3s;
        transform: rotate(-45deg); }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item:before {
      background: transparent !important; }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
      transform: rotate(45deg); }
    .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
      padding-left: 24px; }
      .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
        padding: 8px 30px 8px 15px; }
        .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
          background: none;
          width: auto;
          min-width: auto;
          height: auto;
          line-height: auto; }
  .pro-sidebar .pro-menu:not(.inner-submenu-arrows) .pro-inner-list-item .pro-menu-item.pro-sub-menu .pro-inner-item .pro-arrow-wrapper {
    display: none; }
  .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: #2b2b2b; }
  .pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 0; }
  .pro-sidebar .pro-menu.round .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 4px; }
  .pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    border-radius: 50%; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item {
  position: relative; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .suffix-wrapper,
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .prefix-wrapper {
    opacity: 0; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
    background-color: #2b2b2b;
    z-index: 111; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer; }
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu {
    position: relative; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item {
      pointer-events: none; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
        display: none; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
      height: auto !important;
      position: fixed;
      visibility: hidden;
      min-width: 220px;
      max-width: 270px;
      background-color: transparent;
      max-height: 100%;
      padding-left: 3px; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
        padding-left: 10px; }
      .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
        max-height: 100vh;
        overflow-y: auto;
        background-color: #2b2b2b;
        padding-left: 20px;
        border-radius: 4px; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover > .pro-inner-list-item {
      transition: visibility, transform 0.3s;
      visibility: visible; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
      animation: swing ease-in-out 0.5s 1 alternate; }
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
    .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
      padding: 8px 30px 8px 5px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 20px 8px 35px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-right: 0;
    margin-left: 10px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  right: auto;
  left: 20px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(135deg); }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  transform: rotate(45deg); }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 24px; }
  .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 15px 8px 30px; }
    .pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
      margin-right: 0;
      margin-left: 15px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px; }
  .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
    padding-right: 10px; }
  .pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    padding-left: 0;
    padding-right: 20px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 5px 8px 30px; }

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent; }

.popper-element[data-popper-placement^='left'] > .popper-arrow {
  right: 0;
  border-right: 7px solid #2b2b2b; }

.popper-element[data-popper-placement^='right'] > .popper-arrow {
  left: 0;
  border-left: 7px solid #2b2b2b; }

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out; }

.react-slidedown.transitioning {
  overflow-y: hidden; }

.react-slidedown.closed {
  display: none; }

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
